import * as React from "react"
import { useState, useEffect } from "react"
import Layout from "../../components/Layout"
import Container from "../../components/Container"
import Badge from "../../components/Badge"
import { StaticImage } from "gatsby-plugin-image"
import ReactModal from 'react-modal';
import { MdClose, MdArrowForward, MdArrowBack } from "react-icons/md"

const StoriiCare = () => {
  let [image, setImage] = useState(null);

  const [modalOpen, setModalOpen] = useState(false);

  const renderStaticImage = () => {
    if (image === 1) {
      return <StaticImage placeholder="blurred" src="../../../src/images/freelance/storiicare/1_large.jpg" alt="" className="object-cover" />;
    } else if (image === 2) {
      return <StaticImage aspectRatio={16/9} placeholder="blurred" src="../../../src/images/freelance/storiicare/2_large.jpg" alt="" className="object-cover" />;
    } else if (image === 3) {
      return <StaticImage aspectRatio={16/9 }placeholder="blurred" src="../../../src/images/freelance/storiicare/3_large.jpg" alt="" className="object-cover" />;
    } else if (image === 4) {
      return <StaticImage aspectRatio={16/9} placeholder="blurred" src="../../../src/images/freelance/storiicare/4_large.jpg" alt="" className="object-cover" />;
    } else if (image === 5) {
      return <StaticImage aspectRatio={16/9} placeholder="blurred" src="../../../src/images/freelance/storiicare/5_large.jpg" alt="" className="object-cover" />;
    } else if (image === 6) {
      return <StaticImage aspectRatio={16/9} placeholder="blurred" src="../../../src/images/freelance/storiicare/6_large.jpg" alt="" className="object-cover" />;
    }
  }

  const renderCaption = () => {
    if (image === 1) {
      return <p className="text-sm">Examples of designs for Care Management functionality.</p>;
    } else if (image === 2) {
      return <p className="text-sm">Two variations of the app icon used across the webapp and iOS/Android native apps.</p>;
    } else if (image === 3) {
      return <p className="text-sm">3x3m curved pop-up stand design at an Alzheimer's event in Manchester, 2017.</p>;
    } else if (image === 4) {
      return <p className="text-sm">A landing page design for StoriiCare.</p>;
    } else if (image === 5) {
      return <p className="text-sm">A5 brochure design brochure used for sales and marketing.</p>;
    } else if (image === 6) {
      return <p className="text-sm">Sketch-style marketing graphic design.</p>;
    }
  }

  const leftArrow = () => {
    if (image === 1) {
      setImage(image = 6);
    } else {
      setImage(image = image - 1);
    }
  }

  const rightArrow = () => {
    if (image === 6) {
      setImage(image = 1);
    } else {
      setImage(image = image + 1);
    }
  }

  useEffect(() => {
    if (typeof document !== "undefined") {
      if (modalOpen === true) {
        document.body.style.overflowY = 'hidden'
      } else {
        document.body.style.overflowY = 'scroll'
      }
    }
  },[modalOpen]);
  return (
    <Layout>
      <div>
        <Container showFooter colour="0d1120" title="StoriiCare" backLink>
          <div className="flex">
              <div className="mx-auto">
                <StaticImage placeholder="blurred" alt="" src="../../../src/images/freelance/storii-logo.jpg" className="rounded-xl shadow-lg w-32 mb-10" />
              </div>
          </div>

          <p className="mb-6 max-w-prose text-accent-text">As the sole designer, my work with Storii involved: creating a style guide and branding guidelines to be used across multiple related products; front-end development; UX and UI design; design for print; graphics and icons for web and native apps; apparel design; and creating email marketing campaigns.</p>

          <div className="mb-8">
            <Badge label="Branding" />

            <Badge label="Research" />

            <Badge label="User testing" />

            <Badge label="UX/UI" />

            <Badge label="Front-end" />

            <Badge label="Illustrations/Icons " />

            <Badge label="Print design" />
          </div>

          <div className="flex mb-16">
              <div className="mx-auto">
                  <a href="https://www.storiicare.com" rel="noreferrer"  target="_blank">
                    <button className={'p-2 pl-5 pr-5 bg-transparent border-2 border-main-text text-main-text rounded-xl font-bold transition-colors duration-200 transform hover:bg-white hover:text-black focus:border-2 shadow-lg'}>Visit StoriiCare</button>
                  </a>
              </div>
          </div>

          <div className="grid md:grid-cols-2 gap-12">
            <div onClick={() => {setModalOpen(true); setImage(1)}}>
              <StaticImage placeholder="blurred" alt="Zactly Home Page" src="../../../src/images/freelance/storiicare/1_thumb.jpg" className="rounded-xl shadow-lg hover:opacity-80 cursor-pointer transition-opacity duration-200 transform" />
            </div>
            
            <div onClick={() => {setModalOpen(true); setImage(2)}}>
              <StaticImage placeholder="blurred" alt="" src="../../../src/images/freelance/storiicare/2_thumb.jpg" className="rounded-xl shadow-lg hover:opacity-80 cursor-pointer transition-opacity duration-200 transform" />
            </div>

            <div onClick={() => {setModalOpen(true); setImage(3)}}>
              <StaticImage placeholder="blurred" alt="" src="../../../src/images/freelance/storiicare/3_thumb.jpg" className="rounded-xl shadow-lg hover:opacity-80 cursor-pointer transition-opacity duration-200 transform"/>
            </div>

            <div onClick={() => {setModalOpen(true); setImage(4)}}>
              <StaticImage placeholder="blurred" alt="" src="../../../src/images/freelance/storiicare/4_thumb.jpg" className="rounded-xl shadow-lg hover:opacity-80 cursor-pointer transition-opacity duration-200 transform" />
            </div>

            <div onClick={() => {setModalOpen(true); setImage(5)}}>
              <StaticImage placeholder="blurred" alt="" src="../../../src/images/freelance/storiicare/5_thumb.jpg" className="rounded-xl shadow-lg hover:opacity-80 cursor-pointer transition-opacity duration-200 transform" />
            </div>

            <div onClick={() => {setModalOpen(true); setImage(6)}}>
              <StaticImage placeholder="blurred" alt="" src="../../../src/images/freelance/storiicare/6_thumb.jpg" className="rounded-xl shadow-lg hover:opacity-80 cursor-pointer transition-opacity duration-200 transform" />
            </div>

            <ReactModal
              isOpen={modalOpen}
              shouldCloseOnEsc={true}
              shouldReturnFocusAfterClose={true}
              shouldCloseOnOverlayClick={true}
              ariaHideApp={false}
              onRequestClose={() => {
                setModalOpen(false)
              }}
              className="grid bg-ink-500 text-white lg:rounded-xl shadow-2xl m-4 animate-fade-in-new max-w-screen-lg mx-auto"
              overlayClassName="fixed text-gray-500 items-center overflow-y-scroll justify-center z-50 bg-black bg-opacity-80 left-0 right-0 top-0 bottom-0"
            >
              <div className="grid">
                <div className="ml-auto">
                  <div className="py-6 px-3 sm:px-6 sm:py-6 cursor-pointer">
                    <MdClose className="text-2xl text-white hover:text-gray-300 transition-colors duration-200 transform" onClick={() => setModalOpen(false)} />
                  </div>
                </div>
              </div>

              <div>
                {renderStaticImage()}
              </div>

              <div className="">
                <div className="flex justify-between">
                  <div className="py-6 px-3 sm:px-6 sm:py-6 cursor-pointer"><MdArrowBack className="text-2xl text-white hover:text-gray-300 transition-colors duration-200 transform" onClick={() => leftArrow()} /></div>
                  <div className="py-6 px-3 sm:px-6 sm:py-6">{renderCaption()}</div>
                  <div className="py-6 px-3 sm:px-6 sm:py-6 cursor-pointer"><MdArrowForward className="text-2xl text-white hover:text-gray-300 transition-colors duration-200 transform" onClick={() => rightArrow()} /></div>
                </div>
              </div>
            </ReactModal>
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default StoriiCare